@font-face {
    font-family: 'baskerville';
    src: url('/fonts/baskerville/Baskerville.eot');
    src: url('/fonts/baskerville/Baskerville.eot?#iefix') format('embedded-opentype'),
    url('/fonts/baskerville/Baskerville.woff') format('woff'),
    url('/fonts/baskerville/Baskerville.ttf') format('truetype'),
    url('/fonts/baskerville/Baskerville.svg#new_baskerville_unicode_regular') format('svg');
}

@font-face {
    font-family: 'baskerville';
    src: url('/fonts/baskerville/Baskerville-Bold.eot');
    src: url('/fonts/baskerville/Baskerville-Bold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/baskerville/Baskerville-Bold.woff2') format('woff2'),
    url('/fonts/baskerville/Baskerville-Bold.woff') format('woff'),
    url('/fonts/baskerville/Baskerville-Bold.ttf') format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: 'baskerville';
    src: url('/fonts/baskerville/Baskerville-Italic.eot');
    src: url('/fonts/baskerville/Baskerville-Italic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/baskerville/Baskerville-Italic.woff2') format('woff2'),
    url('/fonts/baskerville/Baskerville-Italic.woff') format('woff'),
    url('/fonts/baskerville/Baskerville-Italic.ttf') format('truetype');
    font-style: italic;
}

@font-face {
    font-family: 'baskerville';
    src: url('/fonts/baskerville/Baskerville-BoldItalic.eot');
    src: url('/fonts/baskerville/Baskerville-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/baskerville/Baskerville-BoldItalic.woff2') format('woff2'),
    url('/fonts/baskerville/Baskerville-BoldItalic.woff') format('woff'),
    url('/fonts/baskerville/Baskerville-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}
